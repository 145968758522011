import React from 'react';

import ListRecent from './components/ListRecent/ListRecent';
import Layout from '../../components/Layout/Layout';
import { RecentWrapper } from './style';

const Recent = () => {
  return (
    <Layout>
      <RecentWrapper>
        <div className="container">
          <div className="title">
            <h1>Recientes</h1>
          </div>
          <div className="recent">
            <ListRecent />
          </div>
        </div>
      </RecentWrapper>
    </Layout>
  );
};

export default Recent;

import React from 'react';
import Button  from '@habi/habi-react-components/dist/Button/button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import useStyles from './style';

const DepartamentModal = ({ openModal, handleCloseModal, message} ) => {
  const classes = useStyles();


  return (
    <Dialog open={openModal} fullWidth={true} maxWidth={'sm'}>
      <DialogContent className={classes.paper}>
        <p className={classes.title}>
          {message}
        </p>
        <div className={classes.box_button}>
          <Button
            id="close"
            typeClass="primary"
            type="submit"
            label="Cerrar"
            className={classes.button}
            onClick={handleCloseModal}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DepartamentModal;

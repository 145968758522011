import axios from 'axios';

const rootUrl =
  process.env.REACT_APP_HABI_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_HABI_TWILIO_API_BASE_PATH;

export const getCallApi = async () => {
  const apiKey = process.env.REACT_APP_TWILIO_API_KEY;
  const endpointUrl = process.env.REACT_APP_ENDPOINT_HABI_TWILIO_API_GET_TOKEN;
  const url = rootUrl + endpointUrl;

  return await axios.get(url, {
    headers: {
      'x-api-key': apiKey,
    },
  });
};

import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';

import { NewCallWrapper, useStyles, PurpleSwitch } from './style';

import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import Modal from '@material-ui/core/Modal';

import CallModal from '../../components/CallModal/CallModal';
import { useCallState } from '../../utils/Hooks/useCallState';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import ModalAlert from '../../components/Modal/Modal';

import { useDispatch, useSelector } from 'react-redux';
import { setCall } from '../../redux/call/call.actions';

const NewCall = () => {
  const dispatch = useDispatch();
  const { open, callCustomer, hangUp, sendDigits } = useCallState();
  const { callSign, number, call, indicateSign } = useSelector(state => state.call);
  const { email } = useSelector(state => state.login);
  const [message, setMessage] = useState('');
  const [openModal, setModal] = useState(false);
  const [typePhone, setTypePhone] = useState(false);

  const classes = useStyles();

  const handleNumber = num => {
    dispatch(setCall({ ...call, number: number + num }));
  };

  const removeNumber = () => {
    dispatch(setCall({ ...call, number: number.substring(0, number.length - 1) }));
  };

  const handleCallSign = e => {
    dispatch(setCall({ ...call, callSign: e.target.value }));
  };

  const handleIndicateSign = e => {
    dispatch(setCall({ ...call, indicateSign: e.target.value }));
  };

  const handleSetPhone = e => {
    setTypePhone(!typePhone);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleCall = () => {
    if (typePhone === true && indicateSign === '' && callSign === '+57') {
      setMessage('¡Por favor, ingresa un departamento!');
      setModal(true);
      return;
    }
    if (number === '') {
      setMessage('¿Qué número vas a marcar?');
      setModal(true);
      return;
    }
    callCustomer(number, email, callSign, indicateSign);
  };

  return (
    <Layout>
      <NewCallWrapper>
        <div className="container container-habi">
          <div className="switch">
            <p>Celular</p>
            <PurpleSwitch
              disableRipple
              classes={{
                track: classes.track,
                checked: classes.checked,
              }}
              data-id="handle-set-phone"
              onClick={() => {
                handleSetPhone();
              }}
            />
            <p>Fijo</p>
          </div>
          <div className="container-card-number">
            {typePhone === true && callSign === '+57' ? (
              <>
                <div className="country">
                  <div className="country-select">
                    <CustomSelect
                      data-id="select-call-sign"
                      handleCallSign={handleCallSign}
                      callSign={callSign}
                      option="country"
                    />
                  </div>
                </div>
                <div className="city">
                  <div className="city-select"></div>
                  <CustomSelect
                    handleIndicateSign={handleIndicateSign}
                    indicateSign={indicateSign}
                    option="city"
                  />
                </div>
                <div className="numberCity">
                  <div className="numberCity-select">
                    <p>{number}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="country">
                  <div className="country-select">
                    <CustomSelect
                      handleCallSign={handleCallSign}
                      callSign={callSign}
                      option="country"
                    />
                  </div>
                </div>
                <div className="number">
                  <div className="number-select">
                    <p>{number}</p>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="keyboard">
            <div>
              <button onClick={() => handleNumber('1')}>1</button>
            </div>
            <div>
              <button onClick={() => handleNumber('2')}>2</button>
            </div>
            <div>
              <button onClick={() => handleNumber('3')}>3</button>
            </div>
            <div>
              <button onClick={() => handleNumber('4')}>4</button>
            </div>
            <div>
              <button onClick={() => handleNumber('5')}>5</button>
            </div>
            <div>
              <button onClick={() => handleNumber('6')}>6</button>
            </div>
            <div>
              <button onClick={() => handleNumber('7')}>7</button>
            </div>
            <div>
              <button onClick={() => handleNumber('8')}>8</button>
            </div>
            <div>
              <button onClick={() => handleNumber('9')}>9</button>
            </div>
            <div>
              <button onClick={() => handleNumber('*')}>*</button>
            </div>
            <div>
              <button onClick={() => handleNumber('0')}>0</button>
            </div>
            <div>
              <button onClick={() => handleNumber('#')}>#</button>
            </div>
          </div>
          <div className="actions">
            <button
              className="actions-call"
              data-id="handle-call"
              onClick={() => handleCall()}
            >
              <CallOutlinedIcon className="actions-call-icon" />
            </button>
            <button
              data-id="remove-number"
              className="actions-remove"
              onClick={removeNumber}
            >
              <BackspaceOutlinedIcon className="actions-call-icon" />
            </button>
          </div>
        </div>
      </NewCallWrapper>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <CallModal hangUp={hangUp} sendDigits={sendDigits} />
        </div>
      </Modal>
      {openModal && (
        <ModalAlert
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          message={message}
        />
      )}
    </Layout>
  );
};

export default NewCall;

import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Mexico from '../../static/assets/img/mexico.svg';
import Colombia from '../../static/assets/img/colombia.svg';

import { CustomInput, useStyles } from './style';

const CustomSelect = ({
  handleCallSign,
  callSign,
  handleIndicateSign,
  indicateSign,
  option,
}) => {
  const classes = useStyles();

  return (
    <>
      {option === 'country' ? (
        <FormControl>
          <Select
            displayEmpty
            value={callSign}
            onChange={handleCallSign}
            input={<CustomInput />}
          >
            <MenuItem value={'+52'}>
              <img src={Mexico} alt="Mexico" className={classes.img} />
            </MenuItem>
            <MenuItem value={'+57'}>
              <img src={Colombia} alt="Colombia" className={classes.img} />
            </MenuItem>
          </Select>
        </FormControl>
      ) : (
        <FormControl>
          <Select
            displayEmpty
            value={indicateSign}
            defaultValue="Departamento"
            onChange={handleIndicateSign}
            input={<CustomInput />}
          >
             <MenuItem value={''}>
              <em>Departamento</em>
            </MenuItem>
            <MenuItem value={'1'}>
              <p>Cundinamarca</p>
            </MenuItem>
            <MenuItem value={'4'}>
              <p>Antioquia</p>
            </MenuItem>
            <MenuItem value={'2'}>
              <p>Valle</p>
            </MenuItem>
            <MenuItem value={'5'}>
              <p>Atlantico</p>
            </MenuItem>
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default CustomSelect;

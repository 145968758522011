function login(state = {}, action = {}) {
  switch (action.type) {
    case 'SET_USER_LOGIN': {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default login;

import styled from 'styled-components';
import {
  skyBlue900,
  white,
  muliBoldFamily,
  muliRegular,
  coral,
  size,
} from '../../StylesConstants';

export const CallWrapper = styled.div`
  background-color: ${skyBlue900};
  .call {
    display: grid;
    height: 100vh;
    font-family: ${muliRegular};
    color: ${white};
    margin: 0% 4% 0% 4%;
    &-info {
      display: grid;
      margin: 13% 0% 2% 0%;
      justify-items: center;
    }
    &-title {
      margin-bottom: 6%;
      text-align: center;
    }
    &-text {
      color: ${white};
      margin-bottom: 8%;
    }
    &-icon {
      color: ${white};
      font-size: 2.4em;
      justify-self: center;
      margin-bottom: 3%;
    }
    &-phone {
      border-radius: 100%;
      display: grid;
      justify-self: center;
      background: ${coral};
      border: 0;
      width: 15vw;
      height: 15vw;
      &-icon {
        justify-self: center;
        align-self: center;
        font-size: 2.4em;
        color: ${white};
      }
    }
    &-field {
      height: 20px;
      width: 50%;
      margin-bottom: 4%;
      box-shadow: 0px 3px 0px 0px rgba(255, 255, 255, 0.5);
    }
  }
  .keyboard {
    column-gap: 2%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    margin: 0% 10% 0% 10%;
    height: 30vh;
    &-btn {
      font-family: ${muliBoldFamily};
      font-size: 1.5em;
      color: ${white};
    }
  }
  .none {
    height: 30vh;
  }
  @media screen and (min-width: ${size.tablet}) {
    .call {
      &-info {
        margin: 3% 0% 0% 0%;
      }
      &-title {
        margin-bottom: 4%;
      }
      &-text {
        margin-bottom: 4%;
      }
      &-phone {
        width: 6vw;
        height: 6vw;
        &-icon {
          font-size: 2em;
        }
      }
      &-field {
        width: 30%;
      }
    }
    .keyboard {
      height: 30vh;
    }
  }
`;

import axios from 'axios';

const apikey = process.env.REACT_APP_USER_ROLE_API_KEY;
const rootUrl =
  process.env.REACT_APP_HABI_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_HABI_COGNITO_API_BASE_PATH;

export const getRolCognito = data => {
  const endpointUrl = process.env.REACT_APP_ENDPOINT_HABI_API_GET_COGNITO_USER_ROLE;
  const url = rootUrl + endpointUrl;

  return axios({
    url: url,
    method: 'post',
    headers: {
      'x-api-key': apikey,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ userId: data.userId }),
  });
};

import styled from 'styled-components';
import { hintPerano000, muliExtraBoldFamily, primary } from '../../StylesConstants';

import landingBackground from '../../static/assets/img/landing-background.svg';

export const LoginWrapper = styled.section`
  background: ${hintPerano000};
  background-image: url(${landingBackground});
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .login-area {
    align-items: center;
    color: ${primary};
    display: grid;
    font: 20px ${muliExtraBoldFamily};
    justify-items: center;
    line-height: 32px;
    grid-template-rows: 64px 64px 100px;
    img {
      width: 60px;
    }
  }
`;

import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import login from './login/login.reducer';
import call from './call/call.reducer';
import recent from './recentcall/recent.reducer';
import { initialState } from './storeConst';

const middleware = [];
export const reducers = combineReducers({
  call,
  login,
  recent,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  initialState,
  composeEnhancer(applyMiddleware(...middleware)),
);

export default store;

import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

import { white } from '../../StylesConstants';

export const CustomInput = withStyles(() => ({
  input: {
    padding: '11px 20px',
    '&:focus': {
      background: white,
    },
  },
}))(InputBase);

export const useStyles = makeStyles(() => ({
  img: {
    width: '2.4em',
  },
}));

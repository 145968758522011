import axios from 'axios';

const rootUrl =
  process.env.REACT_APP_HABI_API_URL_V2 +
  process.env.REACT_APP_ENDPOINT_HABI_TWILIO_API_BASE_PATH;
const apiKey = process.env.REACT_APP_TWILIO_API_KEY;

export const getRecentCallsApi = async data => {
  const endpointUrl = process.env.REACT_APP_ENDPOINT_HABI_TWILIO_GET_CALL_HISTORY;
  const urlGetRecentCalls = rootUrl + endpointUrl;
  
  return await axios.get(urlGetRecentCalls, {
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/json',
    },
    params: { ...data },
  });
};

import React from 'react';
import DialpadIcon from '@material-ui/icons/Dialpad';
import Button from '@material-ui/core/Button';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';

import { CallWrapper } from './style';
import { useSelector } from 'react-redux';

const CallModal = ({ hangUp, sendDigits }) => {
  const { message, to_phone } = useSelector(state => state.call);
  const [phone, setPhone] = React.useState(false);

  const handleClick = () => {
    setPhone(!phone);
  };

  const handleAddNumber = digit => {
    sendDigits(digit);
  };

  return (
    <CallWrapper data-id="call-modal-layout">
      <div className="call">
        <div className="call-info">
          <h1 className="call-title">{message}</h1>
          <p className="call-text">{to_phone}</p>
          <DialpadIcon
            data-id="callModalHandleClick"
            onClick={handleClick}
            className="call-icon"
          />
        </div>
        {phone === true ? (
          <div className="keyboard">
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('1');
              }}
            >
              1
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('2');
              }}
            >
              2
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('3');
              }}
            >
              3
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('4');
              }}
            >
              4
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('5');
              }}
            >
              5
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('6');
              }}
            >
              6
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('7');
              }}
            >
              7
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('8');
              }}
            >
              8
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('9');
              }}
            >
              9
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('*');
              }}
            >
              *
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('0');
              }}
            >
              0
            </Button>
            <Button
              className="keyboard-btn"
              onClick={() => {
                handleAddNumber('#');
              }}
            >
              #
            </Button>
          </div>
        ) : (
          <div className="none"></div>
        )}
        <button className="call-phone" data-id="hang-up-btn" onClick={() => hangUp()}>
          <CallOutlinedIcon className="call-phone-icon" />
        </button>
      </div>
    </CallWrapper>
  );
};

export default CallModal;

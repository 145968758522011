function call(state = {}, action = {}) {
  switch (action.type) {
    case 'SET_CALL_STATE': {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default call;

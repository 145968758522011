import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import Modal from '@material-ui/core/Modal';

import CallModal from '../../../../components/CallModal/CallModal';
import { useCallState } from '../../../../utils/Hooks/useCallState';
import Mexico from '../../../../static/assets/img/mexico.svg';
import Colombia from '../../../../static/assets/img/colombia.svg';
import { ListRecentWrapper } from './style';

const ListRecent = () => {
  const { recent } = useSelector(state => state);
  const { open, callCustomer, hangUp, sendDigits } = useCallState();
  const { email } = useSelector(state => state.login);
  const [number, setNumber] = useState('');

  const handleNumber = number => {
    return number.substr(3);
  };

  const handleCountry = number => {
    if (number.substr(1, 2) === '57') {
      return 1;
    }
    return 2;
  };

  const handleCallCustomber = (phone, email) => {
    callCustomer(phone, email);
    setNumber(phone);
  };

  return (
    <ListRecentWrapper>
      <div className="container container-habi">
        {recent.length > 0 ? (
          recent.map((item, i) => (
            <div className="item" key={i}>
              <img
                className="image"
                src={handleCountry(item.telefono) === 1 ? Colombia : Mexico}
                alt="Pais"
              />
              <div className="item-number">
                <p>{handleNumber(item.telefono)}</p>
              </div>
              <div className="item-icon">
                <button  className="item-btn" onClick={() => handleCallCustomber(item.telefono, email)}>
                  <PhoneOutlinedIcon className="item-phone" />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="error">
            <p>El historial de llamadas se encuentra vacio</p>
          </div>
        )}
      </div>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="paper">
          <CallModal sendDigits={sendDigits} hangUp={hangUp} />
        </div>
      </Modal>
    </ListRecentWrapper>
  );
};

export default ListRecent;

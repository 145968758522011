import styled from 'styled-components';
import {
  muliRegular,
  muliBoldFamily,
  scarpaGray700,
  white,
  brightTurquoise600,
  coral,
  primary,
  size,
} from '../../StylesConstants';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const useStyles = makeStyles(theme => ({
  paper: {
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100vw',
    height: '100vh',
  },
}));

export const PurpleSwitch = withStyles({
  switchBase: {
    color: primary,
    '&$checked': {
      color: primary,
    },
    '&$checked + $track': {
      backgroundColor: primary,
    },
  },
  checked: {},
  track: {},
})(Switch);

export const NewCallWrapper = styled.section`
  margin: 0% 4% 0% 4%;
  font-family: ${muliRegular};
  .container {
    color: ${scarpaGray700};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    &-card-number {
      grid-column: 1 / 5;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.205);
      border-radius: 15px;
      display: grid;
      font-family: ${muliRegular};
      font-size: 2em;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
      margin-bottom: 12%;
      padding: 2%;
    }
  }
  .switch {
    grid-column: 1 / 5;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 3fr 1fr;
    margin-bottom: 10px;
    padding: 0% 20%;
  }
  .number {
    grid-column: 2 / 5;
    display: grid;
    &-select {
      align-self: center;
      justify-self: center;
    }
  }
  .numberCity {
    grid-column: 1 / 5;
    display: grid;
    &-select {
      align-self: center;
      justify-self: center;
    }
  }
  .country {
    grid-column: 1 / 2;
    display: grid;
    &-select {
      align-self: center;
      justify-self: center;
    }
  }
  .city {
    grid-column: 2 / 4;
    display: grid;
    &-select {
      align-self: center;
      justify-self: center;
    }
  }
  .keyboard {
    grid-column: 1 / 5;
    column-gap: 4%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    justify-items: center;
    margin: 0% 10% 25% 10%;
    row-gap: 10%;
    & div {
      width: 100%;
      & button {
        width: 100%;
        text-decoration: none;
        font-family: ${muliBoldFamily};
        font-size: 2.5em;
        color: ${scarpaGray700};
        background-color: ${white};
        border: none;
        cursor: pointer;
      }
    }
  }
  .actions {
    grid-column: 1 / 5;
    column-gap: 4%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-family: ${muliBoldFamily};
    justify-items: center;
    margin: 0% 10% 20% 10%;
    row-gap: 4%;
    &-call {
      background: ${brightTurquoise600};
      color: ${white};
      grid-column: 2 / 3;
      display: inline-grid;
      width: 15vw;
      height: 15vw;
      border-radius: 100%;
      border: 0;
      cursor: pointer;
      &-icon {
        justify-self: center;
        align-self: center;
        font-size: 2.2em;
      }
    }
    &-remove {
      width: 100%;
      text-align: center;
      display: inline-grid;
      color: ${coral};
      grid-column: 3 / 4;
      cursor: pointer;
      background-color: ${white};
      border: none;
    }
  }
  @media screen and (min-width: ${size.tablet}) {
    .container {
      &-card-number {
        margin-bottom: 6%;
      }
    }
    .keyboard {
      margin: 0% 5% 10% 5%;
    }
    .actions {
      margin: 0% 5% 10% 5%;
      &-call {
        width: 7vw;
        height: 7vw;
        &-icon {
          font-size: 2em;
        }
      }
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    .switch {
      grid-column: 2 / 4;
      margin-bottom: 3%;
    }
    .container-card-number {
      margin-bottom: 3%;
    }
    .keyboard {
      margin: 0% 8% 25% 8%;
      margin-bottom: 6%;
    }
    .actions {
      margin: 0% 8% 25% 8%;
      margin-bottom: 0%;
      &-call {
        width: 5vw;
        height: 5vw;
    }
  }
`;

import { makeStyles } from '@material-ui/core/styles';
import { muliRegular } from '../../StylesConstants';

const useStyles = makeStyles(theme => ({
  paper: {
    fontFamily: muliRegular,
    padding: 30,
  },
  title: {
    fontSize: '1.4em',
    padding: '2% 2% 1% 2%',
    textAlign: 'center',
    marginBottom: '10%',
  },
  box_button: {
    gridTemplateColumns: '1fr',
  },
  button: {
    width: '60%',
    margin: 'auto',
  },
}));

export default useStyles;

import styled from 'styled-components';
import { primary, white, size } from '../../StylesConstants';

export const LayoutWrapper = styled.section`
  .header {
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 2% 0% 12% 0%;
  }
  .header img {
    width: 13%;
  }
  .footer {
    background: ${white};
    display: grid;
    bottom: 0;
    position: fixed;
    width: 100%;
    left: 0;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    justify-items: center;
    background: ${white};
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.205);
    &-icon {
      padding: 8px;
      font-size: 3.2em;
      text-decoration: none;
      color: ${primary};
    }
  }
  @media screen and (min-width: ${size.tablet}) {
    .header {
      margin: 2% 0% 5% 0%;
    }
    .header img {
      width: 8%;
    }
  }
  @media screen and (min-width: ${size.tabletBigger}) {
    .header img {
      width: 6%;
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    .header{
      margin: 2% 0% 3% 0%;
    }
    .header img {
      width: 4%;
    }
  }
`;

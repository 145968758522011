import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setCall } from '../../redux/call/call.actions';
import { getCallData } from '../helpers/getCallData';
const Device = require('twilio-client').Device;

export const useCallState = () => {
  const dispatch = useDispatch();
  const { call } = useSelector(state => state);
  const [open, setOpen] = useState(false);

  const openModal = value => {
    setTimeout(() => {
      setOpen(value);
    }, 1500);
  };

  const callCustomer = (phone, user, callSign = '', indicateSign = '') => {
    const phoneNumber = callSign + indicateSign + phone;
    if (phone !== '') {
      Device.destroy();
      getCallData(phoneNumber, user, callSign)
        .then(response => {
          dispatch(
            setCall({
              ...call,
              message: 'Llamando',
            }),
          );
          setOpen(true);
        })
        .catch(e => {
          console.log({ e });
        });
    } else {
      setOpen(true);
      dispatch(setCall({ ...call, message: 'No es posible llamar a este número' }));
      openModal(false);
    }
  };

  const hangUp = () => {
    openModal(false);
    Device.disconnectAll();
  };

  const sendDigits = number => {
    const TwilConn = Device.activeConnection();
    TwilConn.sendDigits(number);
  };

  useEffect(() => {
    Device.on('disconnect', function () {
      openModal(false);
      if (open !== true) {
        dispatch(
          setCall({
            ...call,
            message: 'Llamada Finalizada',
            number: '',
            indicateSign: '',
          }),
        );
      }
    });

    Device.on('connect', function (connection) {
      dispatch(
        setCall({
          ...call,
          to_phone: connection.message.to_phone,
        }),
      );
    });
  }, []);

  return {
    open,
    callCustomer,
    hangUp,
    sendDigits,
  };
};

import styled from 'styled-components';
import { errorPrimary, successPrimary, size } from '../../../../StylesConstants';

export const ListRecentWrapper = styled.div`
  .container {
    margin-bottom: 60px;
  }
  .item {
    display: grid;
    margin-bottom: 6%;
    grid-template-columns: repeat(4, 1fr);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.205);
    border-radius: 15px;
    justify-items: center;
    align-items: center;
    padding: 3% 0% 3% 0%;
    &-number {
      grid-column: 2/4;
      font-size: 1.2em;
    }
    &-phone {
      color: ${successPrimary};
      font-size: 2em;
    }
    &-trash {
      color: ${errorPrimary};
    }
    &-btn {
      background: none;
      border: 0;
    }
  }
  .image {
    width: 1.9em;
  }
  .error {
    height: 400px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
  @media screen and (min-width: ${size.tablet}) {
    .container {
      padding: 0% 5%;
    }
    .item {
      margin-bottom: 2%;
      padding: 3% 3%;
      &-number {
        font-size: 1.5em;
      }
    }
    .error p {
      font-size: 1.3em;
    }
  }
`;

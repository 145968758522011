import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from '../../pages/Home/Home';
import Login from '../../pages/Login/LogIn';
import NewCall from '../../pages/NewCall/NewCall';
import Recent from '../../pages/Recent/Recent';
import AuthenticatedRoute from '../Auth/authenticatedRoute';

const Routes = () => {
  const { isAuthenticated } = useSelector(state => state.login);

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <AuthenticatedRoute
            path="/home"
            component={Home}
            appProps={{ isAuthenticated }}
          />
          <AuthenticatedRoute
            path="/new-call"
            component={NewCall}
            appProps={{ isAuthenticated }}
          />
          <AuthenticatedRoute
            path="/recent"
            component={Recent}
            appProps={{ isAuthenticated }}
          />
        </Switch>
      </div>
    </Router>
  );
};

export default Routes;

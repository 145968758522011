// Grays
export const gray2 = '#565555';
export const gray3 = '#857c81';
export const gray4 = '#b9b9b9';
export const gray5 = '#e0dddd';

// Colors
export const primary = '#7C01FF';
export const primaryGreen = '#7C01FF';
export const primaryGray = '#E3E4E6';
export const secondaryTextBlue = '#2D2D2D';
export const borderGray = 'rgba(35, 55, 65, 0.24)';
export const textBlue = '#233741';
export const textGray = '#E4E7E8';
export const textGray2 = '#808A8F';

export const disabled = '#E4E7E8';
// Fonts
export const fontFamily = 'archia';
export const fontSize = '15px';
export const secondaryFamily = 'archia';
export const subtitle = '#808a8f';
export const primary1 = '#6ca583';
export const accent1 = '#9b8dab';
//size
export const size = {
  mobile: '414px',
  tablet: '744px',
  tabletBigger: '1024px',
  desktop: '1280px',
  desktopBigger: '1440px',
  desktopMaxBigger: '2048px',
};

//NEW_COLORS
export const dark_purple = '#5701B3';
export const purple = '#7C01FF';
export const light_purple = '#AAA7FC';
export const lightPurpleForBgs = 'rgba(170, 167, 252, 0.4)';
export const coral = '#FF5958';
export const aquamarine = '#00F2C3';
export const blue = '#32C1FF';
export const light_blue = '#B2E7FF';
export const green = '#C3FAAB';
export const lightGreen = '#E2FFD7';
export const white = '#FFFFFF';
export const black = '#2D2D2D';
export const dark_grey = '#B8B8B8';
export const light_grey = '#F9FAFC';
export const grey = '#DDDDDD';
export const grey_tab_light = '#F2E5F5';

//NEW COLOR PALETTE

//PURPLE PALETTE
export const electricPurple900 = '#190033';
export const electricPurple800 = '#320066';
export const electricPurple700 = '#4A0199';
export const electricPurple600 = '#6301CC';
export const electricPurple500 = '#7C01FF';
export const electricPurple400 = '#9634FF';
export const electricPurple300 = '#B067FF';
export const electricPurple200 = '#CB99FF';
export const electricPurple100 = '#E5CCFF';
export const electricPurple000 = '#F2E5F5';

//SKY BLUE PALETTE
export const skyBlue900 = '#0A2733';
export const skyBlue800 = '#144D66';
export const skyBlue700 = '#1E7499';
export const skyBlue600 = '#289ACC';
export const skyBlue500 = '#32C1FF';
export const skyBlue400 = '#5BCDFF';
export const skyBlue300 = '#84DAFF';
export const skyBlue200 = '#ADE6FF';
export const skyBlue100 = '#D6F3FF';
export const skyBlue000 = '#EBF9FF';

//BOGOTA BLUE PALETTE
export const bogotaBlue900 = '#242E33';
export const bogotaBlue800 = '#475C66';
export const bogotaBlue700 = '#6B8B99';
export const bogotaBlue600 = '#8EB9CC';
export const bogotaBlue500 = '#B2E7FF';
export const bogotaBlue400 = '#C1ECFF';
export const bogotaBlue300 = '#D1F1FF';
export const bogotaBlue200 = '#E0F5FF';
export const bogotaBlue100 = '#F0FAFF';
export const bogotaBlue000 = '#F7FDFF';

//TURQUOISE PALETTE
export const brightTurquoise900 = '#003027';
export const brightTurquoise800 = '#00614E';
export const brightTurquoise700 = '#009175';
export const brightTurquoise600 = '#00C29C';
export const brightTurquoise500 = '#00F2C3';
export const brightTurquoise400 = '#33F5CF';
export const brightTurquoise300 = '#66F7DB';
export const brightTurquoise200 = '#99FAE7';
export const brightTurquoise100 = '#CCFCF3';
export const brightTurquoise000 = '#E6FEF9';

//GRAY PALETTE = BLACK
export const scarpaGray900 = '#000000';
export const scarpaGray800 = '#252129';
export const scarpaGray700 = '#2E2933';
export const scarpaGray600 = '#433E47';
export const scarpaGray500 = '#6D6970';
export const scarpaGray400 = '#979499';
export const scarpaGray300 = '#C0BFC2';
export const scarpaGray200 = '#D3D2D4';
export const scarpaGray100 = '#E6E5E7';
export const scarpaGray000 = '#FCFCFC';
export const scarpaGray077 = '#c4c4c4';

// TOMATO CORAL
export const tomatoCoral000 = '#FFEEEE';
export const tomatoCoral200 = '#FFBDBC';
export const tomatoCoral300 = '#FF9B9B';
export const tomatoCoral500 = '#FF5958';

// GOLDEN YELLOW
export const goldenYellow000 = '#FEF5E6';
export const goldenYellow100 = '#FCEACC';
export const goldenYellow200 = '#FEFAF2'; //Find if is the name of the color with Yesid
export const goldenYellow400 = '#F3AC33';
export const goldenYellow500 = '#F09700';

// MADANG GREEN
export const madangGreen200 = '#E7FDDD';
export const madangGreen700 = '#759667';

// ERRORS PALETTE = BLACK
export const errorBackground = '#FFEEEE';
export const error = '#E51817';
export const disabledBackground = '#EAEAEB';

// Hint Perano
export const hintPerano000 = '#F7F6FF';
export const hintPerano026 = '#F2E6FF';
export const hintPerano100 = '#EEEDFE';
export const hintPerano200 = '#DDDCFE';
export const hintPerano400 = '#BBB9FD';
export const hintPerano500 = '#AAA7FC';
export const hintPerano600 = '#8886CA';

// Hint Green
export const hintGreen500 = '#E2FFD7';

// Night Blue
export const nightBlue000 = '#FFFFFF';
export const nightBlue100 = '#D3D3D8';
export const nightBlue200 = '#A7A7B1';
export const nightBlue300 = '#7B7B8A';
export const nightBlue400 = '#4F4F63';
export const nightBlue500 = '#23233C';
export const nightBlue600 = '#1C1C30';
export const nightBlue700 = '#151524';
export const nightBlue800 = '#0E0E18';
export const nightBlue900 = '#000000';

// Functional
export const successPrimary = '#009175';
export const successSecondary = '#E6FEF9';
export const successPrimary50 = 'rgba(0, 145, 117, 0.5)';
export const warningPrimary = '#F09700';
export const warningSecondary = '#FEF5E6';
export const warningPrimary50 = 'rgba(240, 151, 0, 0.5)';
export const errorPrimary = '#E61817';
export const errorSecondary = '#FFEEEE';
export const errorPrimary50 = 'rgba(230, 24, 23, 0.5)';
export const informationPrimary = '#006B99';
export const informationSecondary = '#EBF9FF';
export const informationPrimary50 = 'rgba(0, 107, 153, 0.5)';
export const neutralPrimary = '#2E2933';
export const neutralSecondary = '#FFFFFF';
export const neutralPrimary50 = 'rgba(46, 41, 51, 0.5)';
export const plainlightGray = '#F2F2F3';

// Font-family
export const archiaRegular = 'Open Sans';
export const archiaBoldFamily = 'Open Sans Bold';

// Font-family muli
export const muliRegular = 'Muli';
export const muliBoldFamily = 'Muli Bold';
export const muliBlackFamily = 'Muli Black';
export const muliExtraBoldFamily = 'Muli ExtraBold';
export const initialState = {
  login: {
    isAuthenticated: false,
    callToken: false,
    name: '',
    email: '',
  },
  recent: [],
  call: {
    number: '',
    callSign: '+57',
    indicateSign: '',
    to_phone: '',
    message: 'Conectando',
  },
};

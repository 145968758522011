import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PhoneForwardedOutlinedIcon from '@material-ui/icons/PhoneForwardedOutlined';

import formDecoration from '../../static/assets/img/figureOne.svg';
import Layout from '../../components/Layout/Layout';
import { HomeWrapper } from './style';

const Home = () => {
  const { name } = useSelector(state => state.login);
  return (
    <Layout>
      <div className="container-habi">
        <HomeWrapper>
          <div className="container">
            <div className="container-title">
              <h2>Bienvenido!</h2>
              <p>{name}</p>
            </div>
            <div className="container-decoration">
              <img src={formDecoration} alt="figura-decorartiva" />
            </div>
            <div className="container-actions">
              <div className="actions-call">
                <Link to="/new-call">
                  <div className="actions-call-icon">
                    <PhoneForwardedOutlinedIcon className="actions-call-icon-ico" />
                  </div>
                  <div className="actions-call-text">
                    <p>Llamar Ahora</p>
                  </div>
                </Link>
              </div>
              <div className="actions-call">
                <Link to="/recent">
                  <div className="actions-call-icon">
                    <AccessTimeIcon className="actions-call-icon-ico" />
                  </div>
                  <div className="actions-call-text">
                    <p>Recientes</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </HomeWrapper>
      </div>
    </Layout>
  );
};

export default Home;

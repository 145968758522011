import styled from 'styled-components';
import {
  muliRegular,
  muliExtraBoldFamily,
  scarpaGray700,
  size,
} from '../../StylesConstants';

export const RecentWrapper = styled.div`
  margin: 0% 4% 0% 4%;
  .container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .recent {
    font-family: ${muliRegular};
  }
  .title {
    font-family: ${muliExtraBoldFamily};
    color: ${scarpaGray700};
    font-size: 1.5em;
    margin-bottom: 10%;
    justify-self: center;
  }
  @media screen and (min-width: ${size.tablet}) {
    margin: 0% 2% 0% 2%;
    .title {
      font-size: 1.9em;
      margin-bottom: 2%;
    }
  }
`;

import styled from 'styled-components';
import {
  muliRegular,
  muliExtraBoldFamily,
  scarpaGray700,
  white,
  light_blue,
  size,
} from '../../StylesConstants';

export const HomeWrapper = styled.section`
  .container {
    margin: 0% 4% 0% 4%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    &-title {
      color: ${scarpaGray700};
      font-size: 1.8em;
      grid-column: 1 / 4;
      margin-bottom: 8%;
      & h2 {
        font-family: ${muliExtraBoldFamily};
      }
      & p {
        font-family: ${muliRegular};
        font-size: 1em;
      }
    }
    &-decoration {
      grid-column: 3 / 4;
      margin-bottom: 12%;
    }
    &-actions {
      grid-column: 1 / 4;
    }
  }
  .actions-call {
    margin-bottom: 2%;
    &-icon {
      color: ${white};
      background: ${light_blue};
      border-radius: 100%;
      display: inline-grid;
      margin-right: 2%;
      width: 15vw;
      height: 15vw;
      &-ico {
        justify-self: center;
        align-self: center;
      }
    }
    &-text {
      color: ${scarpaGray700};
      align-self: center;
      display: inline-block;
      font-family: ${muliRegular};
      font-size: 1.3em;
      text-decoration: none;
    }
  }
  @media screen and (min-width: ${size.tablet}) {
    .container {
      margin: 0% 4% 10% 4%;
      &-decoration {
        margin-bottom: 1%;
      }
    }
    .actions-call {
      &-icon {
        width: 7vw;
        height: 7vw;
      }
    }
  }
  @media screen and (min-width: ${size.desktop}) {
    .container {
      grid-template-areas:
        'title title title'
        'actions actions decoration';
      &-decoration {
        grid-area: decoration;
      }
      &-actions {
        grid-area: actions;
      }
    }
    .actions-call-icon-ico {
      font-size: 2.5em;
    }
  }
`;

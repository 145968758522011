import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import GoogleLogin from 'react-google-login';

import { LoginWrapper } from './LogIn.style';
import logo from '../../static/assets/img/habi.svg';
import { setLogin } from '../../redux/login/login.actions';
import { getRolCognito } from '../../apis/Login/loginApi';

const LogIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated } = useSelector(state => state.login);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/home');
    }
  }, [history, isAuthenticated]);

  const onRejectLogin = () => {
    history.push('/');
  };

  const onSuccessLogin = response => {
    const profile = response.profileObj;

    if (profile.googleId) {
      const data = {
        userId: profile.googleId,
      };

      getRolCognito(data)
        .then(response => {
          if (response.data.success) {
            dispatch(
              setLogin({
                isAuthenticated: true,
                name: profile.name,
                email: profile.email,
              }),
            );
            history.push('/home');
          } else {
            history.push('/');
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    console.log('latest');
  }, []);

  return (
    <LoginWrapper>
      <section className="login-area">
        <div className="logo-area">
          <img src={logo} alt="logo-img" />
        </div>
        <span>Twillio App</span>
        <div className="pass-button">
          <GoogleLogin
            clientId="1050153132365-2ucv8d21tdc2hotlbtesmup12lfki83t.apps.googleusercontent.com"
            buttonText="Iniciar Sesión"
            onSuccess={onSuccessLogin}
            onFailure={onRejectLogin}
            cookiePolicy={'single_host_origin'}
          />
        </div>
      </section>
    </LoginWrapper>
  );
};

export default LogIn;

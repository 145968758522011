import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import AppsIcon from '@material-ui/icons/Apps';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { setRecentCalls } from '../../redux/recentcall/recent.actions';
import { getRecentCallsApi } from '../../apis/Calls/Recentcalls';

import LogoHabi from '../../static/assets/img/habi.svg';
import { LayoutWrapper } from './style';


const Layout = props => {
  const dispatch = useDispatch();
  const { login } = useSelector(state => state);

  useEffect(() => {
    getRecentCalls();
  }, []);

  const getRecentCalls = async () => {
    const data = {
      agent: login.email,
    };
    await getRecentCallsApi(data)
      .then(response => {
        dispatch(setRecentCalls(response.data.historico));
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <LayoutWrapper>
      <section className="header">
        <img src={LogoHabi} alt="LogoHabi" />
      </section>
      <section className="body">{props.children}</section>
      <section className="footer">
        <Link to="/home">
          <HomeIcon className="footer-icon" />
        </Link>
        <Link to="/new-call">
          <AppsIcon className="footer-icon" />
        </Link>
        <Link to="/recent">
          <AccessTimeIcon className="footer-icon" />
        </Link>
      </section>
    </LayoutWrapper>
  );
};

export default Layout;

import { getCallApi } from '../../apis/Calls/NewCall';
const Device = require('twilio-client').Device;

const countries = {
  '+57': 'CO',
  '+52': 'MX',
};
export const getCallData = async (phoneNumber, user, callSign) => {
  await getCallApi()
    .then(response => {
      //nid remains at -1 to identify that the call was made from twillio app
      let token = response.data.token;
      Device.setup(token, { debug: true });
      setTimeout(() => {
        const params = {
          to_phone: phoneNumber,
          agent: user,
          nid: -1,
          role: ['twilio_app'],
          country: countries[callSign],
        };
        Device.connect(params);
      }, 1500);
    })
    .catch(error => {
      console.log({error});
    });
};
